import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Image from '@components/Image';
import { removeFileExtension } from '@/src/utils';
import FlourishHeader from '@components/FlourishHeader';
import {
  NEXT_PATRONUS,
  PORTRAIT_MAKER,
  PROFILE_PATRONUS,
  PROFILE_SETTINGS,
  PROFILE_WAND,
  SORTING_HAT,
  WW_WAND,
} from '@/src/constants/routes';
import { trackClickEvent } from '@utils/analytics';
import { buildContentfulImageURL } from '@utils/image';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import incompletePatronus from '../img/incomplete-patronus.png';
import incompleteWand from '../img/incomplete-wand.png';
import incompleteSorting from '../img/incomplete-sorting.png';
import incompletePortrait from '../img/incomplete-portrait.png';
import incompleteNewsletter from '../img/incomplete-newsletter.png';
import wandCompleteImg from '../img/wand-complete.png';
import newsletterCompleteImg from '../img/newsletter-complete.png';
import verticalDivider from '../img/vr.svg';
import horizontalDivider from '../img/hr.svg';
import s from './LoggedIn.module.scss';
import ChecklistItem from './ChecklistItem';

// TODO: need to discuss these buttons with product/design teams

const propTypes = {
  profile: PropTypes.shape({
    hogwartsHouse: PropTypes.string,
    patronusName: PropTypes.string,
    patronusFileName: PropTypes.string,
    wand: PropTypes.shape({
      core: PropTypes.string,
      wood: PropTypes.string,
    }),
    avatar: PropTypes.shape({
      avatarId: PropTypes.string,
      url: PropTypes.string,
    }),
    marketingOptInWW: PropTypes.bool,
  }).isRequired,
  patronusData: PropTypes.array,
  header: PropTypes.string.isRequired,
  analyticsParams: PropTypes.shape({
    rootContentfulId: PropTypes.string.isRequired,
    rootContentTypeId: PropTypes.string.isRequired,
  }),
  isInView: PropTypes.bool.isRequired,
};

const LoggedIn = ({ profile, patronusData = null, header, analyticsParams = {}, isInView }) => {
  const router = useRouter();
  const [patronusImage, setPatronusImage] = useState(null);
  const { verticalIndex, componentRef } = useVerticalIndex();
  const {
    hogwartsHouse,
    patronusName,
    patronusFileName,
    wand,
    avatar,
    marketingOptInWW,
    underage,
  } = profile;
  const { rootContentTypeId: contentTypeId, rootContentfulId: contentfulId } = analyticsParams;
  const baseAnalyticsParams = {
    location: 'User Identity Checklist',
    horizontal_index: 0,
    vertical_index: verticalIndex,
    content_name: header,
    content_id: contentfulId,
    content_type: contentTypeId,
  };

  useEffect(() => {
    if (patronusData && patronusFileName) {
      setPatronusImage(
        patronusData.find((item) => item.externalId === removeFileExtension(patronusFileName)),
      );
    }
  }, [patronusFileName, patronusData]);

  const onHouseItemClick = () => {
    if (hogwartsHouse) {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: `/profile/${hogwartsHouse.toLowerCase()}`,
        label: `Your house is ${hogwartsHouse}`,
      });
      router.push(`/profile/${hogwartsHouse.toLowerCase()}`);
    } else {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: SORTING_HAT,
        label: 'Get Sorted',
      });
    }
  };

  const onPatronusItemClick = () => {
    if (patronusName) {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: PROFILE_PATRONUS,
        label: `Your patronus is ${patronusName}`,
        horizontal_index: 1,
      });
      router.push(PROFILE_PATRONUS);
    } else {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: NEXT_PATRONUS,
        label: 'Find your patronus',
        horizontal_index: 1,
      });
    }
  };

  const onWandItemClick = () => {
    if (wand) {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: PROFILE_WAND,
        label: `Your wand is ${wand?.wood}`,
        horizontal_index: 2,
      });
      router.push(PROFILE_WAND);
    } else {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: WW_WAND,
        label: 'Discover your wand',
        horizontal_index: 2,
      });
    }
  };

  const onPortraitItemClick = () => {
    if (avatar?.avatarId) {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: PORTRAIT_MAKER,
        label: 'Your portrait is completed',
        horizontal_index: 3,
      });
      router.push(PORTRAIT_MAKER);
    } else {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: PORTRAIT_MAKER,
        label: 'Create your portrait',
        horizontal_index: 3,
      });
    }
  };

  const onNewsletterItemClick = () => {
    /* istanbul ignore else */
    if (!marketingOptInWW) {
      trackClickEvent({
        ...baseAnalyticsParams,
        destination_url: PROFILE_SETTINGS,
        label: 'Get the newsletter',
        horizontal_index: 4,
      });
    }
  };

  return (
    <div className={s.container} ref={componentRef}>
      {header && <FlourishHeader headerText={header} />}
      <div className={s.checklistContainer}>
        <div className={s.loadedContainer}>
          <div className={s.left}>
            <ChecklistItem
              childNumber={1}
              completed={!!hogwartsHouse}
              cta="Get Sorted"
              ctaLinkUrl={SORTING_HAT}
              StartImage={() => (
                <Image
                  imageSet={[
                    {
                      src: incompleteSorting,
                    },
                  ]}
                  contain
                  center
                  alt="Get Sorted Image"
                />
              )}
              CompletedImage={() => (
                <div className={s.completedContainer}>
                  <div className={[s.ellipse, s[hogwartsHouse.toLowerCase()]].join(' ')} />
                  <div className={[s.houseCrest, s[hogwartsHouse.toLowerCase()]].join(' ')} />
                </div>
              )}
              completedEyebrow="Your House Is"
              completedHeading={hogwartsHouse}
              completedLinkUrl={hogwartsHouse && `/profile/${hogwartsHouse.toLowerCase()}`}
              third
              onClick={() => {
                onHouseItemClick();
              }}
              className={s.checkListItem}
              isInView={isInView}
            />
            <ChecklistItem
              childNumber={2}
              completed={!!patronusName}
              cta="Find Your Patronus"
              ctaLinkUrl={NEXT_PATRONUS}
              StartImage={() => (
                <Image
                  imageSet={[
                    {
                      src: incompletePatronus,
                    },
                  ]}
                  contain
                  center
                  alt="Find Your Patronus Image"
                />
              )}
              CompletedImage={() => {
                /* istanbul ignore if */
                if (!patronusImage) return null;
                return (
                  <div className={s.completedContainer}>
                    <div className={[s.ellipse, s.patronus].join(' ')} />
                    <div className={s.patronusImage}>
                      <Image
                        imageSet={[
                          {
                            src: buildContentfulImageURL(patronusImage?.displayLogo?.file?.url, {
                              width: 550,
                            }),
                          },
                        ]}
                        contain
                        alt="Patronus image"
                      />
                    </div>
                  </div>
                );
              }}
              completedEyebrow="Your Patronus Is"
              completedHeading={patronusName}
              completedLinkUrl={PROFILE_PATRONUS}
              third
              onClick={() => {
                onPatronusItemClick();
              }}
              className={s.checkListItem}
              isInView={isInView}
            />
            <ChecklistItem
              childNumber={3}
              completed={!!wand}
              cta="Discover Your Wand"
              ctaLinkUrl={WW_WAND}
              StartImage={() => (
                <Image
                  imageSet={[
                    {
                      src: incompleteWand,
                    },
                  ]}
                  contain
                  center
                  alt="Discover Your Wand Image"
                />
              )}
              CompletedImage={() => (
                <div className={s.completedContainer}>
                  <div className={[s.ellipse, s.generic].join(' ')} />
                  <div className={s.wandImage}>
                    <Image
                      imageSet={[
                        {
                          src: wandCompleteImg,
                        },
                      ]}
                      contain
                      alt="Patronus image"
                    />
                  </div>
                </div>
              )}
              completedEyebrow="Your Wand Is"
              completedHeading={`${wand?.wood}`}
              completedLinkUrl={PROFILE_WAND}
              third
              onClick={() => {
                onWandItemClick();
              }}
              className={s.checkListItem}
              isInView={isInView}
            />
          </div>
          <div
            className={s.right}
            style={{
              '--vertical-divider': `url(${verticalDivider})`,
              '--horizontal-divider': `url(${horizontalDivider})`,
            }}
          >
            <div className={s.divider} />
            <ChecklistItem
              childNumber={4}
              completed={!!avatar?.avatarId}
              cta="Create Your Portrait"
              type="secondary"
              ctaLinkUrl={PORTRAIT_MAKER}
              StartImage={() => (
                <Image
                  imageSet={[
                    {
                      src: incompletePortrait,
                    },
                  ]}
                  contain
                  center
                  alt="Create Your Portrait Image"
                />
              )}
              CompletedImage={() => (
                <div className={s.portraitContainer}>
                  <Image imageSet={[{ src: avatar?.url }]} contain center alt="Portrait image" />
                </div>
              )}
              completedEyebrow="Your Portrait Is"
              completedHeading="Completed"
              completedLinkUrl={PORTRAIT_MAKER}
              completedLinkText="Customise"
              onClick={() => {
                onPortraitItemClick();
              }}
              className={s.checkListItem}
              isInView={isInView}
            />
            {!underage && (
              <ChecklistItem
                childNumber={5}
                completed={marketingOptInWW}
                cta="Get The Newsletter"
                type="secondary"
                ctaLinkUrl={PROFILE_SETTINGS}
                StartImage={() => (
                  <Image
                    imageSet={[
                      {
                        src: incompleteNewsletter,
                      },
                    ]}
                    contain
                    center
                    alt="Newsletter Image"
                  />
                )}
                CompletedImage={() => (
                  <div className={[s.completedContainer, s.noCursor].join(' ')}>
                    <div className={[s.ellipse, s.generic].join(' ')} />
                    <div className={s.newsletterImage}>
                      <Image
                        imageSet={[
                          {
                            src: newsletterCompleteImg,
                          },
                        ]}
                        contain
                        alt="Patronus image"
                      />
                    </div>
                  </div>
                )}
                completedEyebrow="Newsletter"
                completedHeading="Subscribed"
                className={s.noHover}
                onClick={() => {
                  onNewsletterItemClick();
                }}
                isInView={isInView}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LoggedIn.propTypes = propTypes;
export default LoggedIn;
